<template>
  <div class="bg-white">
    <div
      class="fixed inset-0 z-40 flex lg:hidden"
      :class="navParentIsHidden ? 'hidden' : ''"
      role="dialog"
      aria-modal="true"
    >
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        @after-leave="navParentIsHidden = true"
      >
        <div v-if="!navIsHidden" class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
      </transition>

      <transition
        enter-active-class="transition ease-in-out duration-300 transform"
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition ease-in-out duration-300 transform"
        leave-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <div
          v-if="!navIsHidden"
          class="relative flex w-full max-w-xs flex-col divide-y divide-gray-200 overflow-y-auto bg-gradient-to-b from-white to-gray-100 pb-12 shadow-xl dark:divide-gray-700 dark:from-gray-800 dark:to-gray-900"
        >
          <div class="flex justify-between px-4 py-5">
            <div class="items-center">
              <router-link v-if="isDark" to="/">
                <img class="h-8 w-auto" src="/src/assets/logo_dark.svg">
              </router-link>
              <router-link v-else to="/">
                <img class="h-8 w-auto" src="/src/assets/logo.svg">
              </router-link>
            </div>
            <button
              type="button"
              class="-m-2 inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:text-gray-500 dark:bg-gray-800 dark:text-gray-300 dark:hover:text-gray-200"
              @click="closeNav()"
            >
              <i-heroicons-solid:x />
            </button>
          </div>
          <div class="space-y-6 px-4 py-6 text-gray-800 dark:text-gray-400">
            <div v-for="link in menus" :key="link.name" class="flow-root">
              <NavBarDropDownMobile
                v-if="link.children.length > 0"
                :heading="link.name"
              >
                <router-link
                  :to="link.link"
                  class="text-base font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                >
                  {{ link.name }}
                </router-link>
                <span v-for="childLink in link.children" :key="childLink.name">
                  <router-link
                    :to="childLink.link"
                    class="flex items-center font-medium"
                  >{{ childLink.name }}</router-link>
                </span>
              </NavBarDropDownMobile>
              <router-link
                v-else
                :to="link.link"
                class="-m-2 block p-2 font-medium hover:text-gray-900 dark:hover:text-gray-200"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <header class="relative z-40">
      <nav aria-label="Top">
        <div
          class="bg-gradient-to-r from-white to-gray-100 drop-shadow-lg dark:from-gray-800 dark:to-gray-900"
        >
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center justify-between">
              <!-- Logo (lg+) -->
              <div class="hidden lg:flex lg:items-center">
                <router-link v-if="isDark" to="/">
                  <img class="h-8 w-auto" src="/src/assets/logo_dark.svg">
                </router-link>
                <router-link v-else to="/">
                  <img class="h-8 w-auto" src="/src/assets/logo.svg">
                </router-link>
              </div>

              <div class="hidden h-full lg:flex">
                <!-- Mega menus -->
                <div class="ml-8">
                  <div class="flex h-full justify-center space-x-8">
                    <div v-for="link in menus" :key="link.name" class="flex items-center">
                      <NavBarDropDown
                        v-if="link.children.length > 0"
                        class="flex items-center text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                        :heading="link.name"
                      >
                        <router-link
                          :to="link.link"
                          class="col-span-2 text-base font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                        >
                          {{ link.name }}
                        </router-link>
                        <span v-for="childLink in link.children" :key="childLink.name">
                          <router-link
                            :to="childLink.link"
                            class="flex items-center text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                          >{{ childLink.name }}</router-link>
                        </span>
                      </NavBarDropDown>

                      <router-link
                        v-else
                        :to="link.link"
                        class="flex items-center text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                      >
                        {{ link.name }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Mobile menu and search (lg-) -->
              <div class="flex flex-1 items-center lg:hidden">
                <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
                <button
                  type="button"
                  class="-ml-2 rounded-md bg-white p-2 text-gray-400 dark:bg-gray-800"
                  @click="openNav()"
                >
                  <span class="sr-only">Open menu</span>
                  <i-heroicons-solid:menu />
                </button>
              </div>

              <!-- Logo (lg-) -->
              <router-link v-if="isDark" to="/" class="lg:hidden">
                <img src="/src/assets/thumbnail_dark.svg" class="h-8 w-auto">
              </router-link>
              <router-link v-else to="/" class="lg:hidden">
                <img src="/src/assets/thumbnail.svg" class="h-8 w-auto">
              </router-link>

              <div class="flex flex-1 items-center justify-end">
                <div class="ml-8 flex items-center gap-2">
                  <cgn-dark-mode-switch
                    class="text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-200"
                  />

                  <span class="mx-4 h-6 w-px bg-gray-200 lg:mx-6" aria-hidden="true" />

                  <div class="flow-root">
                    <div class="flex flex-1 items-center justify-end space-x-6 text-gray-500">
                      <AgentAccountDropDown />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script setup lang="ts">
import { isDark } from '~/composables'
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const navIsHidden = ref(true)
const navParentIsHidden = ref(true)
function openNav() {
  navIsHidden.value = false
  navParentIsHidden.value = false
}
function closeNav() {
  navIsHidden.value = true
}
const router = useRouter()
router.beforeEach(() => {
  closeNav()
})

const menus = ref<CognitoMenu[]>([])

async function loadMenu() {
  menus.value = await new CognitoMenu().getMenu('New Site Menu')
}

onMounted(() => {
  loadMenu()
})

onServerPrefetch(async () => {
  await loadMenu()
})
</script>

<template>
  <div v-click-away="close" class="flex flex-col">
    <div class="flex mb-2 justify-start w-full">
      <!-- Item active: "border-brand-600 text-brand-600", Item inactive: "border-transparent text-gray-700 hover:text-gray-800" -->
      <button
        type="button"
        class="flex -mb-px font-medium hover:text-gray-900 dark:hover:text-gray-200 transition-colors ease-out duration-200 text-base border-b-2"
        :class="
          isHidden
            ? 'border-transparent text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100'
            : 'border-brand-600 text-brand-600 hover:text-brand-800 dark:hover:text-brand-300'
        "
        aria-expanded="false"
        @click="toggle()"
      >
        {{ props.heading }}
      </button>
    </div>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <div v-if="!isHidden" class="text-gray-500 sm:text-sm w-full pl-2">
        <div class="flex flex-col gap-y-6">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { directive as vClickAway } from 'vue3-click-away'
const props = defineProps({
  heading: {
    type: String,
  },
})
const isHidden = ref(true)

const toggle = () => {
  isHidden.value = !isHidden.value
}
const close = () => {
  isHidden.value = true
}
const router = useRouter()
router.beforeEach(() => {
  close()
})
</script>

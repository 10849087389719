<template>
  <footer aria-labelledby="footer-heading" class="bg-gray-50 dark:bg-gray-900">
    <h2 id="footer-heading" class="sr-only">
      Footer
    </h2>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="py-20">
        <div class="grid grid-cols-1 lg:grid-cols-12 lg:grid-flow-col lg:gap-x-8 gap-y-16 lg:auto-rows-min">
          <div class="lg:col-span-4 gap-6 grid grid-cols-1">
            <!-- Logo -->
            <div>
              <cgn-link v-if="isDark" to="/">
                <img class="h-8 w-auto" src="/src/assets/logo_dark.svg">
              </cgn-link>

              <cgn-link v-else to="/">
                <img class="h-8 w-auto" src="/src/assets/logo.svg">
              </cgn-link>
            </div>
            <p class="text-gray-600 dark:text-gray-500 text-sm">
              Real-Connect is a unique independent real estate company which connects residential & commercial property
              owners & investors to real estate agents and property managers. We operate Australia wide & have
              registered agents waiting to offer their services from most areas across the country.
            </p>
          </div>
          <!-- Sitemap sections -->
          <div class="mt-10 lg:col-span-5 md:mt-0">
            <h3 class="text-lg font-medium text-gray-600 dark:text-gray-400">
              Links
            </h3>
            <div class="mt-6 gap-6 grid grid-rows-3 grid-flow-col text-sm">
              <cgn-link v-if="!isLoggedIn()" to="/login">
                Login
              </cgn-link>
              <cgn-link v-if="isLoggedIn()" to="/logout">
                Logout
              </cgn-link>
              <cgn-link to="/faqs">
                FAQ
              </cgn-link>
              <cgn-link to="/terms-and-conditions">
                Terms and Conditions
              </cgn-link>
              <cgn-link to="/agent-info">
                I'm an agent
              </cgn-link>
              <cgn-link to="/compare-real-estate-agents-fees">
                How it works
              </cgn-link>
              <cgn-link to="/contact">
                Contact
              </cgn-link>
            </div>
          </div>
          <div class="mt-10 lg:col-span-3 md:mt-0">
            <h3 class="text-lg font-medium text-gray-600 dark:text-gray-400">
              Contact us
            </h3>
            <div class="mt-6 gap-6 grid grid-rows-3 grid-flow-col text-sm">
              <cgn-link to="/contact">
                Contact
              </cgn-link>
            </div>
          </div>
        </div>
      </div>

      <div class="border-t border-gray-100 dark:border-gray-800 py-10 text-center">
        <p class="text-sm text-gray-500">
          &copy; {{ year }}. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { isDark } from '~/composables'
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { isLoggedIn } from '~cognito/plugins/axios'

const year = ref(new Date().getFullYear())
const shopGroups = ref<CognitoGroup[]>([])
onMounted(() => {
  new CognitoGroup().find_many({
    namespace: 'Sell',
    model: 'Product',
  }).then((data) => {
    shopGroups.value = data
  })
})
// TODO better footer data
</script>

<template>
  <div v-click-away="close" class="relative inline-block text-left">
    <div @click="toggle">
      <span class="sr-only">Account</span>
      <span v-if="gravatarSrc" title="My Profile">
        <img :src="gravatarSrc" class="rounded-full bg-white object-cover w-8 h-8">
      </span>
      <span v-else title="Log In">
        <i-heroicons-outline:user-circle
          class="h-8 w-8 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-200"
        />
      </span>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="!isHidden"
        class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-600 focus:outline-none"
      >
        <div v-if="isLoggedIn()" class="py-1">
          <div>
            <router-link
              to="/profile"
              class="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm"
            >
              <i-heroicons-outline:adjustments
                class="mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
              />My profile
            </router-link>
          </div>
          <div>
            <router-link
              to="/leads"
              class="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm"
            >
              <i-heroicons-outline:clipboard-list
                class="mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
              />Lead list
            </router-link>
          </div>
          <div>
            <router-link
              to="/invoices"
              class="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm"
            >
              <i-heroicons-outline:currency-dollar
                class="mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
              />Invoices
            </router-link>
          </div>
        </div>
        <div v-if="isLoggedIn()" class="py-1">
          <div>
            <router-link
              to="/agent-faqs"
              class="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm"
            >
              <i-heroicons-outline:question-mark-circle
                class="mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
              />Agent FAQs
            </router-link>
          </div>
          <div>
            <router-link
              to="/agenttermsconditions"
              class="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm"
            >
              <i-heroicons-outline:document-text
                class="mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
              />Terms and Conditions
            </router-link>
          </div>
          <div>
            <router-link
              to="/servicefee"
              class="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm"
            >
              <i-heroicons-outline:currency-dollar
                class="mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
              />Service Fees
            </router-link>
          </div>
        </div>
        <div class="py-1">
          <div v-if="isLoggedIn()">
            <router-link to="/logout" class="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm">
              <i-heroicons-outline:logout
                class="mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
              />Sign out
            </router-link>
          </div>
          <div v-if="!isLoggedIn()">
            <router-link to="/login" class="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm">
              <i-heroicons-outline:login
                class="mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
              />Sign in
            </router-link>
          </div>
          <div v-if="!isLoggedIn()">
            <router-link
              to="/signup"
              class="text-gray-700 dark:text-gray-300 group flex items-center px-4 py-2 text-sm"
            >
              <i-heroicons-outline:user
                class="mr-3 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
              />Create Account
            </router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import gravatar from 'gravatar'
import { directive as vClickAway } from 'vue3-click-away'
import { useUserStore } from '~cognito/stores/user'
import { isLoggedIn } from '~cognito/plugins/axios'
const userStore = useUserStore()

const gravatarSrc = computed(() => {
  if (userStore.user.thumbnail) {
    return userStore.user.thumbnail
  }
  if (!userStore.user.email) {
    return ''
  }
  return gravatar.url(userStore.user.email, {
    d: 'retro',
  })
})

const isHidden = ref(true)

const toggle = () => {
  isHidden.value = !isHidden.value
}
const close = () => {
  isHidden.value = true
}

const router = useRouter()
router.beforeEach(() => {
  close()
})
</script>

<template>
  <div v-click-away="close" class="flex">
    <div class="relative flex">
      <!-- Item active: "border-brand-600 text-brand-600", Item inactive: "border-transparent text-gray-700 hover:text-gray-800" -->
      <button
        type="button"
        class="
          relative
          z-10
          flex
          items-center
          transition-colors
          ease-out
          duration-200
          text-sm
          font-medium
          border-b-2
          -mb-px
          pt-px
        "
        :class="
          isHidden
            ? 'border-transparent text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100'
            : 'border-brand-600 text-brand-600 hover:text-brand-800 dark:hover:text-brand-300'
        "
        aria-expanded="false"
        @click="toggle()"
      >
        {{ props.heading }}
      </button>
    </div>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <div
        v-if="!isHidden"
        class="absolute top-full inset-x-0 text-gray-500 sm:text-sm"
      >
        <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
        <div
          class="absolute inset-0 top-1/2 shadow-lg"
          aria-hidden="true"
        />

        <div class="relative bg-white dark:bg-gray-800">
          <div class="max-w-7xl mx-auto px-8">
            <div
              class="grid grid-cols-2 items-start gap-y-10 gap-x-8 pt-10 pb-12"
            >
              <div class="grid grid-cols-2 gap-y-10 gap-x-8">
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { directive as vClickAway } from 'vue3-click-away'

const props = defineProps({
  heading: {
    type: String,
  },
})
const isHidden = ref(true)

const toggle = () => {
  isHidden.value = !isHidden.value
}
const close = () => {
  isHidden.value = true
}
const router = useRouter()
router.beforeEach(() => {
  close()
})
</script>
